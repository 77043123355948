#markerSelectorModal {
  border-radius: 4px;
  border: solid 1px #ccc;

  padding: 16px;
  max-width: 90vw;
  width: 400px;

  position: fixed;
  top: calc(50% - 250px);
}

#markerSelectorModal::backdrop {
  background: hsl(0 0% 0% / 50%);
}

.optionsContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* row-gap: 10px; */
}

.closeContainer {
  display: flex;
  justify-content: end;
}

.closeContainer button.danger{
  margin: 0;
}

#markerSelectorModal p{
  margin: 0;
}