.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navigator{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  margin-top: -100px;
}




button{
  cursor: pointer;
}

.secondary-text, label{
  color: #777
}

input{
  border: solid 1px #ccc;
  border-radius: 4px;
  padding: 4px;
}

ul{
  padding: 0;
}

.form-group{
  display: flex;
  flex-direction: column;

  margin-bottom: 8px;

}

button.primary, a.primary, a.primary:visited{
  background-color: white;
  border: solid 1px #2979FF;
  border-radius: 4px;
  padding: 4px 8px;
  color: #2979FF;
  margin: 8px 4px;
  transition: 0.5s;
}

button.danger, a.danger{
  background-color: white;
  border: solid 1px #dc3545;
  border-radius: 4px;
  padding: 4px 8px;
  color: #dc3545;
  margin: 8px 4px;
  transition: 0.5s;
}

button.primary:hover, a.primary:hover{
  color: #0D47A1;
  border-color: #0D47A1;
}

i{
  font-size: x-large;
}

i.primary, .navigator i{
  color: #2979FF;
  margin: 8px 4px;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  color: dimgrey !important;
  border-color: dimgrey !important;
}

button:disabled{
  color: dimgrey !important;
  border-color: dimgrey !important;
  cursor: default;
}