* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: black;
  font-weight: 300;
}

.page_content {
  width: 90%;
  margin: 5% auto;
  position: relative;
  overflow: auto;
  position: relative;
}

.yello {
  background-color: rgba(255, 145, 0, 0.15);
  border-color: #ff9100;
}

.green {
  background-color: rgba(125, 189, 59, 0.15);
  border-color: #7dbd3b;
}

.image-marker__dot.yello,
.image-marker__line.yello {
  background-color: #ff9100;
}

.image-marker__dot.green,
.image-marker__line.green {
  background-color: #7dbd3b;
}

.toolbar_imageSelector {
  float: right;
}

.ImageMarkerContainer {
  width: 80vw;
  max-width: 800px;
  margin: 0 auto;
}

.control-panel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.control-panel p{
  margin: 0;
}

.imageList, .upload-controls{
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 10px;
}


.image-select-btn, .file-upload-btn{
  transition: border 0s, background 0.5s;
  background-color: white;
  text-align: left;
  border: none;
}

.image-select-btn{
  padding: 0;
}

.image-select-btn .image-status{
  background-color: #eee;
}

.image-select-btn.selected{
  border: solid 4px #7dbd3b;
}

.image-select-btn{
  width: 150px;
}

.image-list, .image-select-btn{
  height: 150px;
}
.image-select-btn{
  margin-right: 15px;
}

.image-list-thumbnail-container{
  height: 100%;
}

.image-list-thumbnail{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

label.image-list-label {
  display: inline-block;
  width: 100%;
  color: black;
  text-align: center;
}

.image-marker-container__box--image {
  background: none !important;
  border: none !important;
}
.image-marker-container__box{
  border: none !important;
}



.image-buttons{
  position: relative;
  width: 50px;
  height: 50px;
  padding: 0 !important;
  display: none;
  left: calc(100% - 150px);
  top: calc(-100% - 50px);
}

.delete-image-button i {
  font-size: x-large;
}

.image-container:hover .image-buttons, 
.image-select-btn.selected .image-buttons
{
  display: flex;
}

.online-image{
  border: solid 4px #ccc;
}

.image-marker__text-box__title {
  font-size: 12px;
  padding: 2px;
}

.image-marker__text-box__content {
  font-size: 12px !important;
  padding: 2px !important;
  line-height: 16px !important;
}

.image-marker-container__box--right, .image-marker-container__box--left {
  width: 25% !important
}

.image-marker-container__box--image {
  left: 25.5% !important;
  right: 25.5% !important;
}
