.navigator ul {
    list-style: none;
    display: flex;
    justify-content: center;

    border: solid 1px black;
    padding: 4px;
    margin: 0;
}

.navigator ul li {
    margin: 4px;
    padding:8px;
    margin-right: 16px;
    border-radius: 4px;
}