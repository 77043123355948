.form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    max-width: 90vw;
    width: 600px;
    margin: 0 auto;
    margin-bottom: 100px;
}

.form-container .form{
    display: flex;
    flex-direction: column;
}

.artpiece-list-container p {
    margin: 0;
}

.artpiece-list-container{
    border: solid 1px #ccc;
    padding: 8px;
    width: calc(50% - 22px);
}

.form-container section > div{
    display: flex;
    flex-direction: row;
    
    flex-wrap: wrap;
    gap: 8px;

}

.form-container section > div > div.custom-form-group{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 8px;
}

.form-container p{
    text-align: left;
}

.form-container footer{
    display: flex;
    justify-content: right;
}

.form-container footer button{
    border-radius: 4px;
    border: solid 1px #2979FF;
    padding: 8px;
    margin-right: 4px;
    transition: 0.5s ;
    text-decoration: none;
    background: none;
    color: #2979FF;
}

input.error, input.error:focus{
    border: solid 2px red;
}