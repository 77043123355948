.selection-input-list{
    list-style: none;
    
}

.selection-input-selected-option, .selection-input-option{
    border: solid 1px #ccc;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
    margin-bottom: 8px;
}

.error .selection-input-selected-option.parent, .error .selection-input-selected-option.parent input:focus{
    border: solid 2px red;
}