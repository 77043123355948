.report-list-container{
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 90vw;
    /* width: 800px; */
    margin: auto;
}

.report-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    width: 100%;
}

.existing-reports-list{
    width: 100%;
}

.report-list-item{
    border-radius: 4px;
    border: solid 1px #ccc;

    display: flex;
    flex-direction: row;

    margin-bottom: 8px;
    
    width: 100%;
    text-align: left;

}

.report-list-item p{
    margin: 0;
}

.report-list-item > div{
    padding: 8px;

}

.report-list-item .image-container{
    width: 200px;
    height: 200px;
}

.report-list-item img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    
}


.report-list-item > .info-section{
    flex-grow: 1;
}

.report-list-item > div:nth-child(3){
    display: flex;
    flex-direction: row;
    align-items: center;
}

.form-group:nth-child(1){
    margin-bottom: 8px;
}

.create-button-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.dimensions-list{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
}

.info-section h2{
    margin-bottom: 0;
}
