.form-group.big, .form-group.medium{
    max-width: 90vw;
    width: 100%; 
}

.form-group.small{
    width: calc(33% - 8px);
    max-width: 90vw;
}

.form-group.big textarea {
    border: solid 1px #ccc;
    border-radius: 4px;
    padding: 4px;
    width: 100%;
    resize: vertical;

}

.error input, .error input:focus{
    border: solid 2px red;
}
